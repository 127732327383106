/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
import { color } from 'src/utils/constant'
function ChartColumn({ data }) {
	const options = useMemo(() => {
		return {
			credits: {
				enabled: false,
			},
			title: {
				text: 'User',
				align: 'left',
			},
			xAxis: [
				{
					categories: [...data?.categories],
					crosshair: true,
				},
			],
			scrollbar: {
				enabled: false,
			},
			navigator: {
				enabled: false,
			},
			rangeSelector: {
				enabled: false,
			},
			yAxis: [
				{
					// Primary yAxis
					opposite: true,
					offset: 25, // Adds 10px spacing from the edge

					labels: {
						// format: '{value} ¥',
						style: {
							color: '',
						},
					},
					title: {
						// text: 'User',
						style: {
							color: '',
						},
					},
				},
			],
			tooltip: {
				shared: true,
				formatter: function () {
					let tooltip = ''
					this.points.forEach((point) => {
						tooltip += `Users: <b>${point.y.toLocaleString()}</b>`
					})
					return tooltip
				},
			},
			plotOptions: {
				series: {
					dataLabels: {
						enabled: true,
					},
				},
			},
			legend: {
				align: 'center',
				verticalAlign: 'top',
				y: -7.6,
				floating: true,
				backgroundColor: 'rgba(255,255,255,0.25)',
			},
			series: [
				{
					// name: 'User',
					type: 'column',
					data: [...data?.data],
					// tooltip: {
					// 	valueSuffix: ' ¥',
					// },
					color: color.BlueV2,
				},
			],
		}
	}, [JSON.stringify(data)])
	return (
		<div style={{ width: '100%', height: 'fit-content' }}>
			{options && (
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
					constructorType={'stockChart'}
				/>
			)}
		</div>
	)
}

export default React.memo(ChartColumn)
