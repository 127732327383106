import { Modal, ModalProps } from '@mantine/core'
import { memo } from 'react'

function CustomModal({ children, opened, onClose, ...props }: ModalProps) {
	console.log('ehe')
	return (
		<Modal
			centered
			sx={{
				'.mantine-Modal-overlay': {
					zIndex: 402,
				},
				'.mantine-Modal-inner': {
					width: '100%',
					zIndex: 403,
					padding: 200,
					boxSizing: 'border-box',
					'@media (max-width:900px)': {
						padding: 20,
					},
					'.mantine-Paper-root': {
						padding: 0,
					},
				},
			}}
			opened={opened}
			onClose={onClose}
			{...props}
		>
			{children}
		</Modal>
	)
}

export default memo(CustomModal)
