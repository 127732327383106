/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import { Flex, MantineProvider, Stack, TypographyStylesProvider } from '@mantine/core'
import Content from './Views/Content'
import Header from './Components/Header';
import Footer from './Components/Footer';
import './Styles/index.scss';

function LandingPage() {
	return (
	  <MantineProvider>
		<TypographyStylesProvider>
		  <Header />
		  <Content />
		  <Footer />
		</TypographyStylesProvider>
	  </MantineProvider>
	);
}

export default React.memo(LandingPage)
