import {
	IconArticle,
	IconCategoryFilled,
	IconFileSettings,
	IconSettings,
} from '@tabler/icons-react'
import { FaChartPie, FaHouseUser } from 'react-icons/fa'
import { FaChartColumn, FaPeopleGroup, FaPerson } from 'react-icons/fa6'
import { pathUrl } from 'src/utils/constant'

const style = { height: 20, width: 20 }
const nav = [
	{
		name: 'Home', // Tên của dropdown
		icon: <FaChartColumn style={{ ...style }} />,
		children: [
			{
				name: 'Dashboard',
				to: '/dashboard',
				icon: <FaChartPie style={{ ...style }} />,
			},
		],
	},
	{
		name: 'User', // Tên của dropdown
		icon: <FaHouseUser style={{ ...style }} />,
		children: [
			{
				name: 'Guider',
				to: '/guiders',
				icon: <FaPeopleGroup style={{ ...style }} />,
			},
			{
				name: 'User',
				to: '/users',
				icon: <FaPerson style={{ ...style }} />,
			},
		],
	},
	{
		name: 'Category trails', // Tên của dropdown
		icon: <IconCategoryFilled style={{ ...style }} />,
		to: '/categorytrails',
	},
	{
		name: 'Topic discuss', // Tên của dropdown
		icon: <IconArticle style={{ ...style }} />,
		to: pathUrl.topics,
	},
	{
		name: 'Config phone', // Tên của dropdown
		icon: <IconFileSettings style={{ ...style }} />,
		to: pathUrl.configphone,
	},
	{
		name: 'Settings', // Tên của dropdown
		icon: <IconSettings style={{ ...style }} />,
		to: '/settings',
	},
]
export default nav
