import { Accordion, Text, Container, Title, Badge } from '@mantine/core';
import { FiCoffee, FiCloudSnow, FiAnchor } from "react-icons/fi";

const SectionFive = () => {
    //const theme = useMantineTheme();

    return (
        <section id="section-five">
            <Container>
                <div style={{ marginBottom: 30 }}>
                    <div style={{ textAlign: 'left' }}><Badge variant="filled" color="green">FAQs</Badge></div>
                    <Text color="black">
                        <Title order={1} style={{ marginTop: 10 }}>Woah, Frequently asked questions here</Title>
                    </Text>
                </div>

                <Accordion variant="contained">
                    <Accordion.Item value="item1">
                        <Accordion.Control icon={<FiCoffee size={20} color={"#fab005"} />}>
                            What is UniVini?
                        </Accordion.Control>
                        <Accordion.Panel>UniVini is a platform for learning languages, traveling, and discovering new cultures.</Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item value="item2">
                        <Accordion.Control icon={<FiCloudSnow size={20} color={"#fab005"} />}>
                            Is it free?
                        </Accordion.Control>
                        <Accordion.Panel>Currently, UniVini is free.</Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item value="item3">
                        <Accordion.Control icon={<FiAnchor size={20} color={"#fab005"} />}>
                            Who can join UniVini Network
                        </Accordion.Control>
                        <Accordion.Panel>Everyone - Who want to hangout, traveling, hiking, practice languages.</Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
            </Container>
        </section>
    );

};

export default SectionFive;